import { lazy, useState, Suspense, memo, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { buttonVariants } from '../ui/button';
import { Triggers } from '@/stores/my';
import { linkColor } from '@/styles/links';
import { Anchor } from '@/components/ui/anchor';
import { useSessionState } from '@/lib/hooks/use-session-state';
import { Skeleton } from '@/components/ui/skeleton';

const LazyAuth = lazy(() => import('./LazyAuth'));
const LazySession = lazy(() => import('./LazySessionConnect'));

const defaultVariant = buttonVariants();

// auth dropdown menu content
export const AuthDropdownMarketingMenuComponent = () => {
  const { initialLoad, $session } = useSessionState();
  const [open, setOpen] = useState(false);
  const [trigger, setTrigger] = useState<Triggers>(Triggers.login);
  const [loadSession, setLoadSession] = useState<boolean>(false);

  const onLoginEvent = () => setTrigger(Triggers.login);
  const onRegisterEvent = () => setTrigger(Triggers.register);

  useEffect(() => {
    const hasRedirect = window.location.search || window.location.hash;

    if (open || hasRedirect) {
      setLoadSession(true);
    }
  }, [loadSession, open]);

  const authed = !!$session?.access_token;

  // load this here if we are redirecting if not in the modal.
  return (
    <>
      {loadSession ? <LazySession /> : null}

      <Dialog open={open} onOpenChange={setOpen}>
        <Anchor
          className={`transition ${authed && !initialLoad ? defaultVariant : `hidden ${defaultVariant.replace('inline-flex', '')}`}`}
          href={'/dashboard'}
        >
          Dashboard
        </Anchor>
        <div
          className={`flex gap-3 items-center transition ${!authed && !initialLoad ? '' : 'hidden'}`}
        >
          <DialogTrigger
            onClick={onLoginEvent}
            className={`${buttonVariants({
              variant: 'default',
            })} uppercase font-mono bg-black text-white dark:bg-white dark:text-black`}
          >
            <span>Sign In</span>
          </DialogTrigger>
          <DialogTrigger
            onClick={onRegisterEvent}
            className={`${buttonVariants({
              variant: 'outline',
            })} uppercase font-mono dark:border-white border-black`}
          >
            <span>Register</span>
          </DialogTrigger>
        </div>

        <DialogContent className="sm:max-w-[425px]" forceMount>
          <div className="max-w-screen-sm container text-black dark:text-white">
            <DialogHeader className="sr-only">
              <DialogTitle className="capitalize text-center">Spider</DialogTitle>
              <DialogDescription className="text-center">Authenticate to start.</DialogDescription>
            </DialogHeader>

            <Suspense fallback={<Skeleton className="w-full min-h-[26rem]" />}>
              <LazyAuth trigger={trigger} />
            </Suspense>
          </div>
          <div className="flex place-content-center items-center gap-3 py-2 text-muted-foreground text-sm justify-center">
            <Anchor className={linkColor} href={'/privacy'} target="__blank">
              Privacy Policy
            </Anchor>
            <span className="text-xs">|</span>
            <Anchor className={linkColor} href={'/eula'} target="__blank">
              Terms of Use
            </Anchor>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export const AuthDropdownMarketingMenu = memo(AuthDropdownMarketingMenuComponent);
